const menuConfig = [
  {
    title: 'Home',
    path: '/'
  },
  {
    title: 'About Me',
    path: '/about'
  },
  {
    title: 'Contact',
    path: '/coming-soon'
  },
  {
    title: 'Blog',
    path: '/coming-soon-blog'
  }

];

export default menuConfig;
